import { createGlobalStyle } from 'styled-components';

import { fonts } from './utils/typo';
import breakpoints from './utils/breakpoints';
import colors from './utils/colors';

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    position: relative;
  }

  html,
  body {
    width: 100%;
    height: 100%;
    ${fonts.light};
    font-size: 16px;
    font-weight: 300;
    color: ${colors.dark};
  }

  body {
    padding-top: 80px;
    overflow-x: hidden;
  }

  p {
    line-height: 1.7;
  }

  strong {
    ${fonts.bold};
  }

  h1,
  .title {
    ${fonts.bold};
    font-size: 1.4em;
    text-transform: uppercase;
    line-height: 1.4;
  }

  #root {
    height: 100%;
  }

  .fade-enter {
    opacity: 0;
    z-index: 1;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 250ms ease-in;
  }

  .container {
    max-width: ${breakpoints.XXL};
    margin: auto;
  }

  .container.space {
    padding: 25px;
  }

  .text-center {
    text-align: center !important;
  }

  .text-right {
    text-align: right !important;
  }

  @media (min-width: ${breakpoints.LG}) {
    a:hover {
      opacity: 0.7;
    }
  }
`;

export default GlobalStyles;
