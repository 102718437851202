import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import colors from "../utils/colors";
import breakpoints from "../utils/breakpoints";
import SocialMedia from "./SocialMedia";

const Background = styled.div`
  background: ${colors.almostWhite};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 110px 30px 90px;
  line-height: 1.8;

  a {
    color: ${colors.dark};
    text-decoration: none;
  }

  @media (min-width: ${breakpoints.MD}) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-start;
  }

  @media (min-width: ${breakpoints.LG}) {
    padding: 110px 150px 90px;
  }
`;

const Column = styled.div`
  padding: 7px 0;

  @media (min-width: ${breakpoints.MD}) {
    &:first-child {
      justify-self: start;
    }

    &:last-child {
      justify-self: end;
    }
  }
`;

const GetInTouchBanner = () => (
  <Background>
    <Wrapper className="container">
      <Column>
        <Link className="title" to="/contact/">
          Get in touch
        </Link>
      </Column>
      <Column>
        <a href="mailto:info@bostwickstudio.com">info@bostwickstudio.com</a>
      </Column>
      <Column>
        <SocialMedia />
      </Column>
    </Wrapper>
  </Background>
);

export default GetInTouchBanner;
