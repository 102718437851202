import React from "react";
import styled, { css } from "styled-components";
import { Helmet } from "react-helmet";

import breakpoints from "../utils/breakpoints";
import colors from "../utils/colors";
import { fonts } from "../utils/typo";
import BreakTextAt from "../components/BreakTextAt";
import SocialMedia from "../components/SocialMedia";

const FORM_SIZE = 65;

const FullScreen = styled.div`
  display: flex;
  min-height: 100%;
  background: ${colors.dark};
  color: ${colors.white};

  > div {
    width: 100%;
  }
`;

const Title = styled.div`
  text-align: center;

  @media (min-width: ${breakpoints.LG}) {
    width: ${100 - FORM_SIZE}%;
    float: left;
    text-align: left;
  }
`;

const EmailAndPhone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  line-height: 1.7;

  a {
    color: ${colors.white};
    text-decoration: none;
  }

  @media (min-width: ${breakpoints.LG}) {
    width: ${100 - FORM_SIZE}%;
    align-items: flex-start;
    float: left;
    padding-top: 130px;
    text-align: left;
  }
`;

const FormSection = styled.div`
  @media (min-width: ${breakpoints.LG}) {
    width: ${FORM_SIZE}%;
    float: right;
  }

  .title {
    font-size: 1.2em;
    padding: 40px 0 50px;
    text-align: center;
    text-transform: none;

    @media (min-width: ${breakpoints.LG}) {
      text-align: left;
      padding: 0 0 20px;
    }
  }
`;

const FormElement = styled.div`
  padding: 20px 0;

  input,
  textarea {
    width: 100%;
    padding: 18px 11px;
    font-size: 1em;
    background: ${colors.dark};
    border: 0;
    border-bottom: solid 2px ${colors.white};
    color: ${colors.white};
    ${fonts.light};
  }
`;

const Button = styled.button`
  background: ${colors.dark};
  color: ${colors.white};
  ${fonts.bold};
  border: 0;
  padding: 12px 20px;
  text-transform: uppercase;
  font-size: 1em;
  cursor: pointer;

  &:hover {
    color: ${colors.dark};
    background: ${colors.white};
  }

  ${(props) =>
    props.disabled &&
    css`
      color: ${colors.dark};
      background: ${colors.white};
      cursor: default;
    `}
`;

class ContactUs extends React.Component {
  state = {
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
    _status: "not-sent",
  };

  handleFormSubmit(e) {
    e.preventDefault();

    this.setState(
      {
        _status: "sending",
      },
      async () => {
        try {
          await fetch("/submit-form.php", {
            method: "POST",
            cache: "no-cache",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(this.state),
          }).then((response) => response.json());

          this.setState({
            _status: "sent",
          });
        } catch (err) {
          console.error(err);

          alert(
            "Error submitting the form. Please try again or contact us directly to info@bostwickstudio.com"
          );

          this.setState({
            _status: "no-sent",
          });
        }
      }
    );
  }

  render() {
    const { _status } = this.state;
    const isSending = _status === "sending";
    const isSent = _status === "sent";

    return (
      <FullScreen>
        <Helmet>
          <title>Contact - Bostwick Studio</title>
          <meta
            name="description"
            content="Want to know more? Want to grab a coffee? Let's chat."
          />
        </Helmet>
        <div className="container space">
          <Title>
            <h1>Get in touch</h1>
            <p>
              <BreakTextAt at={breakpoints.LG}>
                <span>We're based in New York and</span>
                <span>work with clients across the</span>
                <span>U.S. and around the globe.</span>
              </BreakTextAt>
            </p>
          </Title>

          <FormSection>
            {isSent ? (
              <h2 className="title">
                Thank you for contacting us, you'll hear from us soon{" "}
                <span role="img" aria-label="coffee">
                  ☕
                </span>
                !
              </h2>
            ) : (
              <React.Fragment>
                <h2 className="title">
                  Want to know more?
                  <br />
                  Want to grab a coffee? Let's chat.
                </h2>
                <form onSubmit={(e) => this.handleFormSubmit(e)}>
                  <FormElement>
                    <label>What's your name?</label>
                    <input
                      type="text"
                      onChange={(e) => this.setState({ name: e.target.value })}
                      disabled={isSending}
                      required
                    />
                  </FormElement>
                  <FormElement>
                    <label>And your email?</label>
                    <input
                      type="email"
                      onChange={(e) => this.setState({ email: e.target.value })}
                      disabled={isSending}
                      required
                    />
                  </FormElement>
                  <FormElement>
                    <label>Would you mind sharing your number?</label>
                    <input
                      type="phone"
                      onChange={(e) =>
                        this.setState({ phoneNumber: e.target.value })
                      }
                      disabled={isSending}
                    />
                  </FormElement>
                  <FormElement>
                    <label>Ask away or spill your thoughts</label>
                    <textarea
                      onChange={(e) =>
                        this.setState({ message: e.target.value })
                      }
                      disabled={isSending}
                    />
                  </FormElement>
                  <FormElement className="text-right">
                    <Button type="submit" disabled={isSending}>
                      {isSending ? "Sending..." : "Send"}
                    </Button>
                  </FormElement>
                </form>
              </React.Fragment>
            )}
          </FormSection>

          <EmailAndPhone>
            <a href="mailto:info@bostwickstudio.com">info@bostwickstudio.com</a>
            <SocialMedia />
          </EmailAndPhone>
        </div>
      </FullScreen>
    );
  }
}

ContactUs.displayName = "ContactUs";

export default ContactUs;
