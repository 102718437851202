import React from 'react';
import styled from 'styled-components';

import GetInTouchBanner from '../components/GetInTouchBanner';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Content = styled.div`
  flex-grow: 1;
`;

const withFooter = Component => props => (
  <Wrapper>
    <Content>
      <Component {...props} />
    </Content>
    <GetInTouchBanner />
  </Wrapper>
);

export default withFooter;
