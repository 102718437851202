import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.ul`
  display: flex;
  margin: 0 -10px;
`;

const SocialMediaItem = styled.li`
  padding: 0 10px;

  &:not(:last-child):after {
    content: '|';
    padding-left: 20px;
  }
`;

const SocialMedia = props => (
  <Wrapper {...props}>
    <SocialMediaItem>
      <a
        href="https://www.facebook.com/bostwickstudio/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Facebook
      </a>
    </SocialMediaItem>
    <SocialMediaItem>
      <a
        href="http://instagram.com/bostwickstudio"
        target="_blank"
        rel="noopener noreferrer"
      >
        Instagram
      </a>
    </SocialMediaItem>
  </Wrapper>
);

export default SocialMedia;
