import React from 'react';
import styled from 'styled-components';
import get from 'lodash/fp/get';
import findIndex from 'lodash/fp/findIndex';
import { Redirect, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import PROJECTS from '../config/projects.config';
import bkp from '../utils/breakpoints';
import colors from '../utils/colors';
import withFooter from '../hocs/withFooter';
import { fonts } from '../utils/typo';

const Title = styled.h1`
  padding: 50px 0 30px;
`;

const Image = styled.img`
  width: 100%;
  margin: 10px 0;

  @media (min-width: ${bkp.MD}) {
    margin: 15px 0;
  }
`;

const BackToProjects = styled(Link)`
  display: block;
  margin: 15px 0 30px;
  padding: 30px 10px;
  background: ${colors.dark};
  color: ${colors.white};
  text-decoration: none;
  ${fonts.bold};
  text-transform: uppercase;
  font-size: 1.3em;
  text-align: center;

  &:hover {
    opacity: 0.8;
  }

  &,
  &:hover {
    transition: opacity ease 200ms;
  }
`;

const ImageList = ({ slug, images }) => (
  <ul>
    {images.map((filename, index) => {
      const mobile = require(`../images/projects/${slug}/mobile/${filename}`);
      const desktop = require(`../images/projects/${slug}/desktop/${filename}`);
      return (
        <li key={index}>
          <picture>
            <source srcSet={mobile} media="(max-width: 900px)" />
            <Image srcSet={desktop} />
          </picture>
        </li>
      );
    })}
  </ul>
);

const ProjectDetail = props => {
  const slug = get('match.params.slug', props);
  const projectIndex = findIndex(['slug', slug], PROJECTS);

  if (projectIndex < 0) return <Redirect to="/page-not-found" />;

  const { name, description, images } = PROJECTS[projectIndex];

  return (
    <div className="container">
      <Helmet>
        <title>{name} - Bostwick Studio</title>
        <meta name="description" content={description} />
      </Helmet>
      <Title className="text-center">{name}</Title>
      <ImageList {...{ slug, name, images }} />
      <BackToProjects to="/work/">Back to Projects</BackToProjects>
    </div>
  );
};

ProjectDetail.displayName = 'ProjectDetail';

export default withFooter(ProjectDetail);
