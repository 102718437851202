import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import LINKS from '../config/menu.config';
import colors from '../utils/colors';
import { fonts } from '../utils/typo';
import breakpoints from '../utils/breakpoints';

const Wrapper = styled.div`
  z-index: 999;
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${colors.almostWhite};
  align-items: center;
  justify-content: center;
`;

const Close = styled.button`
  z-index: 2;
  position: absolute;
  top: 7px;
  right: 0;
  padding: 30px;
  border: 0;
  background: transparent;
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 25px;
    height: 2px;
    background: ${colors.black};
    top: 30px;
    left: 21px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

const List = styled.ul`
  text-align: center;
`;

const Item = styled.li`
  a {
    ${props => css`
      @keyframes moveFade-${props.index} {
        0% {
          opacity: 0;
          transform: translateX(-25px);
        }
        ${(props.index + 1) * 10}% {
          opacity: 0;
          transform: translateX(-25px);
        }
        100% {
          opacity: 1;
          transform: translateX(0);
        }
      }
    `}

    display: block;
    padding: 20px 15px;
    ${fonts.bold};
    text-transform: uppercase;
    text-decoration: none;
    color: ${colors.dark};
    font-size: 1.5em;
    ${props => css`
      animation: ${'moveFade-' + props.index} 1.1s;
    `}

    @media (min-width: ${breakpoints.LG}) {
      &:hover {
        opacity: 0.5;
      }

      &, &:hover {
        transition: all 200ms ease;
      }
    }
  }
`;

const MenuList = ({ onClose }) => (
  <Wrapper>
    <Close onClick={onClose} />
    <List>
      {LINKS.map(({ to, name }, index) => (
        <Item key={index} index={index}>
          <Link to={to} onClick={onClose}>
            {name}
          </Link>
        </Item>
      ))}
    </List>
  </Wrapper>
);

export default MenuList;
