import React from 'react';
import { Helmet } from 'react-helmet';

const Error404 = () => (
  <div className="container text-center">
    <Helmet>
      <title>Page not found - Bostwick Studio</title>
      <meta name="description" content="Page not found" />
    </Helmet>
    <h1 style={{ paddingTop: '50px' }}>PAGE NOT FOUND</h1>
  </div>
);

Error404.displayName = 'Error404';

export default Error404;
