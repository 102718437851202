import React from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import GlobalStyles from './GlobalStyles';
import ScrollToTop from './components/ScrollToTop';
import Header from './components/Header';
import Services from './pages/Services';
import Error404 from './pages/Error404';
import AboutUs from './pages/AboutUs';
import Projects from './pages/Projects';
import ContactUs from './pages/ContactUs';
import ProjectDetail from './pages/ProjectDetail';

const styles = {};

styles.fill = {
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0
};

styles.content = {
  ...styles.fill,
  height: '100%'
};

styles.scroll = {
  ...styles.fill,
  position: 'fixed'
};

const isProduction = () => process.env.NODE_ENV === 'production';

class App extends React.Component {
  constructor(props) {
    super(props);

    window.onbeforeunload = () => {
      window.scrollTo(0, 0);
    };

    if (isProduction()) {
      ReactGA.initialize('UA-65368311-1');
    }
  }

  render() {
    return [
      <GlobalStyles />,
      <Router>
        <Route
          render={({ location }) => {
            if (isProduction()) {
              ReactGA.pageview(location.pathname);
            }

            return (
              <React.Fragment>
                {/* <div style={styles.fill}> */}
                <ScrollToTop>
                  <Header />
                  <TransitionGroup style={styles.content}>
                    <CSSTransition
                      key={location.key}
                      classNames="fade"
                      timeout={300}
                    >
                      <React.Fragment>
                        <Switch>
                          {/* <SmoothScroll style={styles.scroll}> */}
                          <Route exact path="/" component={AboutUs} />
                          <Route exact path="/about/" component={AboutUs} />
                          <Route exact path="/services/" component={Services} />
                          <Route exact path="/work/" component={Projects} />
                          <Route exact path="/contact/" component={ContactUs} />
                          <Route
                            exact
                            path="/work/:slug/"
                            component={ProjectDetail}
                          />
                          {/* </SmoothScroll> */}
                          <Route component={Error404} />
                        </Switch>
                      </React.Fragment>
                    </CSSTransition>
                  </TransitionGroup>
                </ScrollToTop>
                {/* </div> */}
              </React.Fragment>
            );
          }}
        />
      </Router>
    ];
  }
}

export default App;
