import React from 'react';
import styled from 'styled-components';

import LogoGray from '../images/logo-gray.svg';
import breakpoints from '../utils/breakpoints';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;

  @media (min-width: ${breakpoints.LG}) {
    overflow: initial;
  }
`;

const BigLogo = styled.img.attrs({
  style: ({ left, top }) => ({
    transform: `translateX(-${left}px) translateY(${top}px)`
  })
})`
  position: absolute;
  width: 800%;
  left: 0%;
  bottom: -15%;
  opacity: 0.15;

  @media (min-width: ${breakpoints.SM}) {
    width: 600%;
  }

  @media (min-width: ${breakpoints.MD}) {
    width: 500%;
  }

  @media (min-width: ${breakpoints.LG}) {
    width: 350%;
    bottom: -20%;
  }

  @media (min-width: ${breakpoints.XL}) {
    width: 250%;
  }
`;

class BigAnimatedLogo extends React.Component {
  state = this.getPositions();

  componentDidMount() {
    window.addEventListener('scroll', () => {
      this.setState(this.getPositions());
    });
  }

  getPositions() {
    const position = window.scrollY;
    const left = position / 1.5;
    const top = position / 10;

    return {
      left,
      top
    };
  }

  render = () => (
    <Wrapper>
      <BigLogo src={LogoGray} left={this.state.left} top={this.state.top} />
    </Wrapper>
  );
}

export default BigAnimatedLogo;
