import React from 'react';
import styled, { css } from 'styled-components';
import { Helmet } from 'react-helmet';

import colors from '../utils/colors';
import BigLaptopSmall from '../images/big-laptop-small.jpg';
import BigLaptop from '../images/big-laptop.jpg';
import withFooter from '../hocs/withFooter';
import Section from '../components/Section';
import Floating from '../components/Floating';
import FloatingWhiteBox from '../components/FloatingWhiteBox';
import breakpoints from '../utils/breakpoints';

const WithBackgroundColor = styled.div`
  ${props => css`
    background: ${props.color};
  `}
`;

const CustomSection = styled(Section)`
  margin-bottom: -200px;

  @media (min-width: ${breakpoints.MD}) {
    margin-bottom: -130px;
  }
`;

const Title = styled.h1`
  padding: 60px 0 90px;

  @media (min-width: ${breakpoints.MD}) {
    padding: 100px 0 30px;
  }
`;

const CustomFloating = styled(Floating)`
  margin-top: -80px;

  @media (min-width: ${breakpoints.MD}) {
    margin-top: 0;
  }
`;

const Services = styled.ul`
  text-align: center;

  @media (min-width: ${breakpoints.MD}) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-start;
  }

  > li {
    padding: 25px 0;

    &:first-child {
      margin-top: -40px;
    }

    &:last-child {
      margin-bottom: -40px;
    }

    @media (min-width: ${breakpoints.MD}) {
      margin: 0 !important;

      > span {
        display: inline-block;
        text-align: left;
      }
    }
  }
`;

const ServiceList = styled.ul`
  @media (min-width: ${breakpoints.MD}) {
    margin-top: 10px;
  }

  li {
    padding: 5px 0;

    @media (min-width: ${breakpoints.MD}) {
      padding: 10px 0;
    }
  }
`;

const Picture = styled.div`
  height: 520px;
  background-position: center;
  background-size: cover;

  @media (min-width: ${breakpoints.MD}) {
    height: 800px;
  }

  ${props => css`
    background-image: url('${props.image}');
  `}

  ${props =>
    props.imageLarge &&
    css`
    @media (min-width: ${breakpoints.MD}) {
      background-image: url('${props.imageLarge}');
    }
  `}
`;

const SERVICES = [
  {
    name: 'Branding',
    services: [
      'Brand Platform',
      'Storytelling',
      'Strategic Planning',
      'Concept',
      'Logos',
      'Stationery Design',
      'Graphic Guidelines',
      'Collateral Material'
    ]
  },
  {
    name: 'Content',
    services: [
      'Art Direction',
      'Copywriting',
      'Social Media',
      'Illustration',
      'Photography',
      '3D Rendering'
    ]
  },
  {
    name: 'Coding',
    services: [
      'UX Design',
      'Front + Backend Development',
      'CMS & Data Management',
      'API Integration',
      'SEO',
      'ADA Compliance',
      'Technical Maintenance'
    ]
  }
];

const servicesToString = () =>
  SERVICES.reduce(
    (arr, { name, services }) => [...arr, name, ...services],
    []
  ).join(', ');
const Home = () => (
  <React.Fragment>
    <Helmet>
      <title>Services - Bostwick Studio</title>
      <meta
        name="description"
        content={`As a full-service branding & digital design agency, we cover a large variety of services: ${servicesToString()}`}
      />
    </Helmet>
    <CustomSection
      minHeight
      className="container space"
      background={colors.almostWhite}
      color={colors.dark}
    >
      <Title className="text-center">Our Services</Title>
      <CustomFloating offsetTop={-30}>
        <FloatingWhiteBox>
          <Services>
            {SERVICES.map(({ name, services }) => (
              <li key={name}>
                <span>
                  <h2 className="title">{name}</h2>
                  <ServiceList>
                    {services.map(service => (
                      <li key={`service-${service}`}>{service}</li>
                    ))}
                  </ServiceList>
                </span>
              </li>
            ))}
          </Services>
        </FloatingWhiteBox>
      </CustomFloating>
    </CustomSection>
    <WithBackgroundColor color="#000">
      <div className="container">
        <Picture image={BigLaptopSmall} imageLarge={BigLaptop} />
      </div>
    </WithBackgroundColor>
  </React.Fragment>
);

Home.displayName = 'Home';

export default withFooter(Home);
