import styled, { css } from 'styled-components';

import breakpoints from '../utils/breakpoints';
import colors from '../utils/colors';

const Section = styled.section`
  display: flex;
  flex-direction: column;
  background: ${colors.white};

  ${props =>
    props.minHeight &&
    css`
      min-height: 500px;

      @media (min-width: ${breakpoints.LG}) {
        min-height: 800px;
      }
    `}

  ${props =>
    props.contentCenter &&
    css`
      justify-content: center;
      align-items: center;
    `}

  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}

  ${props =>
    props.background &&
    css`
      background: ${props.background};
    `}
`;

export default Section;
