import React from "react";
import styled, { css } from "styled-components";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import PROJECTS from "../config/projects.config";
import colors from "../utils/colors";
import bkp from "../utils/breakpoints";
import Floating from "../components/Floating";
import withFooter from "../hocs/withFooter";
import { fonts } from "../utils/typo";
import { Redirect } from "react-router-dom";

const Section = styled.section`
  display: block;
  padding: 30px 10px 0;

  &:nth-child(2n) {
    background: ${colors.almostWhite};
  }

  @media (min-width: ${bkp.MD}) {
    height: 800px;
    padding: 100px 30px;
  }
`;

const LinkWrapper = styled(Link)`
  display: block;
  color: ${colors.dark};
  text-decoration: none;

  &:hover {
    opacity: 1;
  }

  @media (min-width: ${bkp.MD}) {
    width: 100%;
    height: 100%;
    max-width: 1200px;
    margin: auto;

    &:hover {
      .picture {
        transform: scale(1.02);
      }

      .text-box {
        transform: scale(1.1);
      }
    }

    &,
    &:hover {
      .picture {
        transition: transform 300ms ease;
      }
    }
  }

  @media (min-width: ${bkp.XL}) {
    max-width: 1400px;
  }
`;

const Picture = styled.div`
  height: 350px;
  background-size: cover;
  background-position: center center;

  ${(props) =>
    props.mobile &&
    css`
      background-image: url("${props.mobile}");
    `};

  @media (min-width: ${bkp.MD}) {
    width: 60%;
    height: 100%;

    ${(props) =>
      props.desktop &&
      css`
        background-image: url("${props.desktop}");
      `};

    ${(props) =>
      props.right &&
      css`
        margin-left: 40%;
      `};
  }
`;

const TextBox = styled(Floating)`
  padding: 25px 15px;
  align-items: center;
  text-align: left;
  display: flex;
  flex-direction: column;

  @media (max-width: ${bkp.MD}) {
    transform: translateY(0) !important;
  }

  /* MIN */
  @media (min-width: ${bkp.MD}) {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 500px;
    width: 40%;
    height: 60%;
    top: 0;
    right: 10%;
    background: ${colors.almostWhite};

    ${(props) =>
      props.left &&
      css`
        right: auto;
        left: 10%;
        background: white;
      `};
  }

  @media (min-width: ${bkp.LG}) {
    &:hover {
      background: ${colors.dark};
      color: ${colors.white};
    }

    &,
    &:hover {
      transition: background 300ms ease !important;
    }
  }
`;

const ProjectName = styled.h2`
  text-transform: uppercase;
  text-align: center;
`;

const ViewLink = styled.span`
  text-transform: uppercase;
  ${fonts.bold};
  font-size: 0.9em;
  padding-top: 10px;
`;

const HighlightsList = styled.ul`
  margin: 20px 0 25px 12px;
  list-style: circle;

  li {
    padding: 5px 0;
  }
`;

const Highlights = ({ list }) => (
  <HighlightsList>
    {list.map((value) => (
      <li key={value}>{value}</li>
    ))}
  </HighlightsList>
);

const Projects = () => {
  if (process.env.REACT_APP_DISABLE_PROJECTS) {
    return <Redirect from="/work" to="/" />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Work - Bostwick Studio</title>
        <meta
          name="description"
          content="Projects we have done in the past years: Branding, Concept, Logos, Graphic Design, Content, Art Direction, Copywriting, Social Media, Illustration, Photography, 3D Rendering, Coding, Front End Development, Back End Development, CMS, Data Management, API Integration, SEO, ADA Compliance, and more."
        />
      </Helmet>
      {PROJECTS.map(({ name, highlights, slug, mainImage, images }, index) => {
        const image = mainImage || images[0];
        const isOdd = index % 2 !== 0;
        return (
          <Section key={index}>
            <LinkWrapper to={`/work/${slug}/`}>
              <Picture
                mobile={require(`../images/projects/${slug}/mobile/${image}`)}
                desktop={require(`../images/projects/${slug}/desktop/${image}`)}
                right={isOdd}
                className="picture"
              />
              <TextBox className="text-box" left={isOdd} offsetTop={30}>
                <ProjectName className="title">{name}</ProjectName>
                {highlights && <Highlights list={highlights} />}
                <ViewLink className="viewLink">View Project</ViewLink>
              </TextBox>
            </LinkWrapper>
          </Section>
        );
      })}
    </React.Fragment>
  );
};

Projects.displayName = "Projects";

export default withFooter(Projects);
