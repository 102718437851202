import React from "react";
import styled from "styled-components";

import Logo from "./Logo";
import Menu from "./Menu";

const Wrapper = styled.header`
  z-index: 8;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.9);
`;

const Header = () => (
  <Wrapper>
    <Logo />
    <Menu />
  </Wrapper>
);

Header.displayName = "Header";

export default Header;
