import React from "react";
import styled, { css } from "styled-components";
import { Helmet } from "react-helmet";

import breakpoints from "../utils/breakpoints";
import colors from "../utils/colors";
import BigAnimatedLogo from "../components/BigAnimatedLogo";
import BreakTextAt from "../components/BreakTextAt";
import TextContent from "../components/TextContent";
import Floating from "../components/Floating";
import Woman from "../images/creative-woman.jpg";
import Man from "../images/tech-man.jpg";
import withFooter from "../hocs/withFooter";
import Section from "../components/Section";
import FloatingWhiteBox from "../components/FloatingWhiteBox";

const WhatIs = styled.h1`
  max-width: 300px;
  text-align: center;

  @media (min-width: ${breakpoints.MD}) {
    max-width: 740px;
  }
`;

const WhoWeAre = styled.div`
  padding-top: 50px;

  @media (min-width: ${breakpoints.LG}) {
    padding-top: 100px;
  }
`;

const WeAreList = styled.ul`
  li {
    padding: 15px 0;

    @media (min-width: ${breakpoints.LG}) {
      padding: 10px 0;
    }
  }
`;

const SquarePictureWrapper = styled.div`
  @media (min-width: ${breakpoints.XL}) {
    display: flex;
    min-width: 1350px;
    height: 800px;
    justify-content: flex-end;

    ${(props) =>
      props.pictureLeft &&
      css`
        justify-content: flex-start;
      `}
  }
`;

const Square = styled(Floating)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 600px;
  padding: 30px;
  background: ${colors.white};
  text-align: center;

  @media (min-width: ${breakpoints.MD}) {
    height: 500px;
    padding: 30px 52px;
  }

  @media (min-width: ${breakpoints.XL}) {
    position: absolute;
    z-index: 1;
    width: 55%;
    height: calc(100% - 100px);
    left: 0;
    bottom: 100px;
    text-align: left;

    ${(props) =>
      props.right &&
      css`
        left: auto;
        right: 0;
      `}
  }

  ${(props) =>
    props.dark &&
    css`
      color: ${colors.white};
      background: ${colors.dark};
    `}
`;

const Picture = styled.div`
  height: 600px;
  background-size: cover;
  background-position: center;

  @media (min-width: ${breakpoints.MD}) {
    height: 800px;
  }

  @media (min-width: ${breakpoints.XL}) {
    width: 60%;
    height: 100%;
  }

  ${(props) => css`
    background-image: url("${props.image}");
  `}
`;

const AboutUs = () => (
  <React.Fragment>
    <Helmet>
      <title>
        Bostwick Studio - full-service branding & digital design agency based in
        New York City
      </title>
      <meta
        name="description"
        content="We're a full-service branding & digital design agency based in New York City. We're probably not the first shop you've checked out, and we may not be the last, but here's why we're right for you."
      />
    </Helmet>
    <Section contentCenter minHeight>
      <BigAnimatedLogo />
      <Floating negative startAt={0} throttle={0.35}>
        <WhatIs>
          Great design is about form and function.
          <br />
          We do both.
        </WhatIs>
      </Floating>
    </Section>
    <Section
      minHeight
      className="container space"
      background={colors.dark}
      color={colors.white}
    >
      <WhoWeAre className="text-center">
        <TextContent title="Who we are">
          <BreakTextAt at={breakpoints.LG}>
            <span>
              Welcome to Bostwick Studio. We're a full-service branding &
              digital design
            </span>
            <span>
              agency based in New York City. We're probably not the first shop
              you've checked
            </span>
            <span>
              out, and we may not be the last, but here's why we're right for
              you.
            </span>
          </BreakTextAt>
        </TextContent>
      </WhoWeAre>
      <Floating>
        <FloatingWhiteBox>
          <WeAreList className="text-center">
            <li>We're artists and vision-makers. </li>
            <li>We're powered by expert problem solvers. </li>
            <li>
              We're designers, developers, photographers, and music lovers.{" "}
            </li>
            <li>We're writers and storytellers.</li>
            <li>We're nimble, scalable, and forward-thinking.</li>
            <li>We're as big or small as you need us to be.</li>
            <li>
              <strong>
                We're the kind of people you want to grab a coffee with.
              </strong>
            </li>
          </WeAreList>
        </FloatingWhiteBox>
      </Floating>
    </Section>
    <Section minHeight background={colors.almostWhite}>
      <div className="container space text-center">
        <TextContent
          TitleTag="h2"
          title="Firm in our philosophy, nimble in approach"
        >
          {" "}
          <BreakTextAt at={breakpoints.LG}>
            <span>
              At Bostwick Studio, we have no one, singular way of doing things.
              Because the
            </span>
            <span>
              landscape is changing by the day. Every time we begin a project,
              we wipe the
            </span>
            <span>
              slate clean, start with fresh eyes, and work with you in a truly
              collaborative
            </span>
            <span>
              partnership toward hotter creative and smarter ways to bring it to
              life.
            </span>
          </BreakTextAt>
        </TextContent>
      </div>
    </Section>
    <Section>
      <div className="container">
        <SquarePictureWrapper>
          <Square dark>
            <TextContent title="Design" TitleTag="h2">
              Visual design is the attention-grabber, first impression, and
              lasting memory all in one. It’s what pulls you in, what keeps you
              engaged in the story, and it’s what you remember after you walk
              away. From subtle instances like traffic signs to highly aesthetic
              content like social media posts, everything is a function of
              design — all carefully curated to get the most relevant message
              across in the most meaningful way.
            </TextContent>
          </Square>
          <Picture image={Woman} />
        </SquarePictureWrapper>
      </div>
    </Section>
    <Section background={colors.almostWhite}>
      <div className="container">
        <SquarePictureWrapper pictureLeft>
          <Square right>
            <TextContent title="Technology" TitleTag="h2">
              There’s more to it than just a pretty face. Behind everything
              that’s visible lies a well-conceived strategy. It’s the technology
              that makes it all even possible. It’s being a rockstar behind the
              curtains, translating design into web speak so the star on stage
              can shine.
            </TextContent>
          </Square>
          <Picture image={Man} />
        </SquarePictureWrapper>
      </div>
    </Section>
  </React.Fragment>
);

AboutUs.displayName = "AboutUs";

export default withFooter(AboutUs);
