const brasserieAzur = {
  slug: 'brasserie-azur',
  name: 'Brasserie Azur',
  description:
    'Branding, art direction and web design for Brasserie Azur, a French Restaurant with a Mediterranean twist in the heart of Midtown Miami. Brasserie Azur is a unique mix of ingredients that come together in an urban chic scene that serves fresh, French classics, in a hip ambiance.',
  highlights: ['Branding', 'Content', 'Coding'],
  mainImage: 'main.jpg',
  images: [
    '1.jpg',
    'main.jpg',
    '2.jpg',
    '3.jpg',
    '4.jpg',
    '5.jpg',
    '6.jpg',
    '7.jpg',
    '8.jpg',
    '9.jpg'
  ]
};

const financialAdvisors = {
  slug: 'financial-advisors',
  name: 'Financial Advisors',
  description: 'A financial advising company based in Miami, Fl.',
  highlights: ['Branding', 'Content'],
  mainImage: 'main.jpg',
  images: ['1.jpg', '2.jpg', '3.jpg']
};

const silversea = {
  slug: 'silversea',
  name: 'Silversea',
  description:
    'Silversea Cruises is a luxury cruise line with its headquarters in Monaco, with offices worldwide. BostwickStudio collaborated with the Miami creative team, in the creation of several collateral pieces.',
  highlights: ['Branding'],
  mainImage: 'main.jpg',
  images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg']
};

const mcuBuilders = {
  slug: 'mcu-builders',
  name: 'MCU Builders',
  description:
    'Photography and web design for MCU, an ultra-luxury residential builder and contractor in South Florida. ',
  highlights: ['Branding', 'Content', 'Coding'],
  mainImage: 'main.jpg',
  images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
  website: 'mcubuilders.com'
};

const lesSenteursTropeziennes = {
  slug: 'les-senteurs-tropeziennes',
  name: 'Les Senteurs Tropeziennes',
  description:
    'Les Senteurs Tropeziennes captivates the most beautiful and magical scents of Saint Tropez presenting them through the forms of perfumes, candles and body creams. The collateral design done for this brand was inspired by the timeless elegance and freshness of the French Riviera.',
  highlights: ['Branding', 'Content'],
  mainImage: 'main.jpg',
  images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg', '7.jpg']
};

const scrippsNetwork = {
  slug: 'scripps-network',
  name: 'Scripps Network',
  description:
    'Presentation design for Scripps Network, a leader in lifestyle media in the home, food and travel categories.',
  highlights: ['Branding'],
  mainImage: 'main.jpg',
  images: ['main.jpg', '1.jpg', '2.jpg']
};

const soenergy = {
  slug: 'soenergy',
  name: 'SoEnergy',
  description:
    'Web Design for Soenergy International., an energy services provider for organizations and industries worldwide, headquartered in Miami, Florida.',
  highlights: ['Branding', 'Coding'],
  mainImage: 'main.jpg',
  images: ['1.jpg', '2.jpg'],
  website: 'soenergy.com'
};

const angelMccoughtry = {
  slug: 'angel-mccoughtry',
  name: 'Angel McCoughtry',
  description:
    "Promotional items, stationery and media kit for Women's National Basketball Association (WNBA) professional basketball player, Angel Mccoughtry.",
  highlights: ['Branding', 'Content'],
  mainImage: 'main.jpg',
  images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg']
};

const minaGroup = {
  slug: 'mina-group',
  name: 'MINA Group',
  description:
    'Mina Group is a San Francisco-based restaurant management company specializing in creating and operating upscale, innovative restaurant concepts by Chef Michael Mina. Bostwick Studio collaborated in the creation of collateral pieces for various restaurants.',
  highlights: ['Branding', 'Content'],
  mainImage: 'main.jpg',
  images: [
    '1.jpg',
    '2.jpg',
    '3.jpg',
    '4.jpg',
    '5.jpg',
    '6.jpg',
    '7.jpg',
    '8.jpg',
    '9.jpg',
    '10.jpg',
    '11.jpg'
  ]
};

const perryEllisInternational = {
  slug: 'perry-ellis-international',
  name: 'Perry Ellis International',
  description:
    'Corporate presentation and rebranding of collateral material for Perry Ellis, an international clothing company that includes a portfolio of widely-known brands such as Original Penguin, Laundry, Callaway, Gotcha, Havanera, Savane, Ben Hogan, Grand Slam, amongst others.',
  highlights: ['Branding'],
  mainImage: 'main.jpg',
  images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg']
};

const logos = {
  slug: 'logos',
  name: 'Logos',
  description: 'The very beginnings of some of our branding projects.',
  mainImage: 'main.jpg',
  images: [
    '1.jpg',
    '2.jpg',
    '3.jpg',
    '4.jpg',
    '5.jpg',
    '6.jpg',
    '7.jpg',
    '8.jpg',
    '9.jpg'
  ]
};

export default [
  brasserieAzur,
  financialAdvisors,
  silversea,
  mcuBuilders,
  lesSenteursTropeziennes,
  scrippsNetwork,
  soenergy,
  angelMccoughtry,
  minaGroup,
  perryEllisInternational,
  logos
];
