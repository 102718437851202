import styled, { css } from 'styled-components';

const BreakTextAt = styled.span`
  span {
    padding-right: 5px;

    ${props =>
      props.at &&
      css`
        @media (min-width: ${props.at}) {
          display: block;
        }
      `}
  }
`;

export default BreakTextAt;
