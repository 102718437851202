import styled from 'styled-components';

import colors from '../utils/colors';
import breakpoints from '../utils/breakpoints';

const FloatingWhiteBox = styled.div`
  padding: 50px 30px;
  margin: 25px 0;
  line-height: 1.5;
  color: ${colors.dark};
  background: ${colors.white};

  @media (min-width: ${breakpoints.LG}) {
    margin: 0 50px;
    padding-top: 80px;
    padding-bottom: 80px;
    margin-top: 0;
  }
`;

export default FloatingWhiteBox;
