import React from "react";
import styled from "styled-components";

import colors from "../utils/colors";
import MenuList from "./MenuList";

const Icon = styled.div`
  &,
  &:before,
  &:after {
    display: block;
    width: 25px;
    height: 2px;
    background: ${colors.black};
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 0;
  }

  &:before {
    top: -8px;
  }

  &:after {
    top: 8px;
  }
`;

const Expand = styled.button`
  padding: 15px;
  border: 0;
  background: transparent;
  cursor: pointer;
`;

class Menu extends React.Component {
  state = {
    show: false
  };

  toggle() {
    this.setState({
      show: !this.state.show
    });
  }

  render() {
    return (
      <React.Fragment>
        <Expand onClick={this.toggle.bind(this)}>
          <Icon />
        </Expand>
        {this.state.show && <MenuList onClose={this.toggle.bind(this)} />}
      </React.Fragment>
    );
  }
}

export default Menu;
