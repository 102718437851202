import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div.attrs({
  style: ({ move }) => ({
    transform: `translateY(${move}px)`
  })
})`
  z-index: 1;
`;

class Floating extends React.Component {
  elem = null;
  observer = null;
  debug = false;

  constructor(props) {
    super(props);

    this.state = {
      enabled: false,
      move:
        props.startAt != null
          ? props.startAt
          : window.innerHeight * props.throttle + props.offsetTop,
      prevPosition: window.scrollY
    };

    this.onScroll = this.onScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);

    let firstTime = true;

    this.observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          const enable = entry.intersectionRatio > 0;
          this.setState({
            enabled: enable,
            prevPosition: window.scrollY
          });

          // This will move the element to the expected position
          // in case the element is visible when the component got mounted
          if (enable && firstTime) {
            this.setState(
              {
                move:
                  this.state.move -
                  this.elem.getBoundingClientRect().height *
                    entry.intersectionRatio *
                    this.props.throttle
              },
              () => this.onScroll()
            );
          }

          firstTime = false;
        });
      },
      {
        threshold: 0
      }
    );

    this.observer.observe(this.elem);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  log(...args) {
    if (!this.debug) return;
    console.log(...args);
  }

  onScroll() {
    const { move, prevPosition, enabled } = this.state;
    const { throttle } = this.props;

    if (!enabled) return;
    this.log('onScroll ENABLED', move);

    const currentPosition = window.scrollY;
    let scrolledDown = currentPosition > prevPosition;

    const diff = scrolledDown
      ? currentPosition - prevPosition
      : prevPosition - currentPosition;

    this.log('move:', move, 'diff:', diff);

    if (this.props.negative) {
      scrolledDown = !scrolledDown;
    }

    this.setState({
      move: scrolledDown ? move - diff * throttle : move + diff * throttle,
      prevPosition: currentPosition
    });
  }

  render() {
    const { className, children } = this.props;
    const { move } = this.state;
    return (
      <Wrapper move={move} ref={e => (this.elem = e)} className={className}>
        {children}
      </Wrapper>
    );
  }
}

Floating.defaultProps = {
  throttle: 0.2,
  offsetTop: 0
};

export default Floating;
