export default [
  {
    to: "/",
    name: "About Us",
  },
  {
    to: "/services/",
    name: "Services",
  },
  {
    to: "/contact/",
    name: "Contact Us",
  },
];
