import React from 'react';
import styled from 'styled-components';

const Text = styled.p`
  padding-top: 10px;
`;

const TextContent = ({ title, children, TitleTag }) => (
  <React.Fragment>
    <TitleTag className="title">{title}</TitleTag>
    <Text>{children}</Text>
  </React.Fragment>
);

TextContent.defaultProps = {
  TitleTag: 'h1'
};

export default TextContent;
