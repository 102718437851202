import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import File from "../images/logo.svg";

const Image = styled.img`
  margin: 15px;
`;

const Logo = () => (
  <Link to="/">
    <Image src={File} width="136" height="44" />
  </Link>
);

export default Logo;
